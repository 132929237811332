<template>
  <div class="news-list-page">
    <div class="news-bg">
      <div class="nav-title">{{ navTitle }}</div>
      <el-divider class="line" content-position="left"></el-divider>
      <div class="menu" v-if="showMenu">
        <router-link
          :to="{ name: 'newsList', params: { type: 3 } }"
          class="menu-link"
          >重点要闻</router-link
        >
        <span class="seperator">/</span>
        <router-link
          :to="{ name: 'newsList', params: { type: 5 } }"
          class="menu-link"
          >地方动态</router-link
        >
      </div>
      <ul class="news-list">
        <li
          class="news-item news-item-text"
          v-for="item in newsList"
          :key="item.id"
          @click="goToDetail(item)"
        >
          <span class="title">{{ item.title }}</span>
          <div class="br"></div>
          <span class="news-item-text">{{
            item.publish_time.slice(0, 10)
          }}</span>
        </li>
      </ul>
      <div class="page-container">
        <el-pagination
          :current-page.sync="page"
          :total="totalPage"
          layout="prev, pager, next"
          :page-size="10"
          @current-change="pageChange"
          :hide-on-single-page="true"
        ></el-pagination>
      </div>
    </div>
    <div class="article-footer"></div>
  </div>
</template>

<script>
import NewsAPI from "@/api/v1/news";
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "",
  data() {
    return {
      newsList: [],
      page: 1,
      totalPage: 0,
      newsCache: {},
    };
  },
  computed: {
    showMenu() {
      let type = parseInt(this.$route.params.type);
      return type === 3 || type === 5;
    },
    listType() {
      let type = parseInt(this.$route.params.type);
      return type;
    },
    ...mapState({
      activeIndex: (state) => {
        return state.navbar.activeIndex;
      },
    }),
    navTitle() {
      if (this.activeIndex === "3") {
        return "通知公告";
      } else if (this.activeIndex === "4") {
        return "新闻中心";
      } else if (this.activeIndex === "5") {
        return "政策法规";
      } else if (this.activeIndex === "6") {
        return "相关业务";
      }
      return "";
    },
  },
  watch: {
    listType(val) {
      let type = val.toString();
      let path = { 1: "3", 3: "4", 2: "5", 5: "4", 6: "6" };
      let index = path[type];
      let data = { activeIndex: index };
      store.commit("navbar/SET_ACTIVEINDEX", data);
      this.clearData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    clearData() {
      (this.newsList = []),
        (this.page = 1),
        (this.totalPage = 0),
        (this.newsCache = {}),
        this.getData();
    },
    getData() {
      const self = this;
      if (self.newsCache[self.page] !== undefined) {
        self.newsCache = self.newsCache[self.page];
        return;
      }
      let type = this.$route.params.type;
      NewsAPI.getList(this.page - 1, 20, null, null, type).then((response) => {
        let list = response.list;
        this.newsList = list;
        self.newsCache[self.params] = list;
        self.totalPage = response.total;
      });
    },
    pageChange() {
      this.getData();
    },
    goToDetail(article) {
      let url;
      if (article.external_link) {
        url = article.external_link;
      } else {
        let type = this.$route.params.type;
        url = this.$router.resolve({
          name: "articleDetail",
          params: { from: type, id: article.id },
        }).href;
      }
      window.open(url, "_blank");
    },
  },
  beforeRouteEnter(to, from, next) {
    console.log("beforeRouteEnter");
    store.commit("navbar/SET_POSITION_BOTTOM");
    let type = to.params.type.toString();

    let path = { 1: "3", 3: "4", 2: "5", 5: "4", 6: "6" };
    let index = path[type];
    let data = { activeIndex: index };
    store.commit("navbar/SET_ACTIVEINDEX", data);
    next();
  },
};
</script>

<style lang="stylus" scoped>
@media only screen and (min-width: 600px) {
  .news-list-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;

    .news-bg {
      width: 800px;
      background: white;
      padding-bottom: 120px;
    }
  }

  .nav-title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
    margin-top: 45px;
  }

  .line {
    margin: 18px 55px 0px 55px;
    height: 2px;
    background: #2C53B0;
    width: auto;
  }

  .news-center-page {
    background: #f6f6f6;
    height: 100%;

    .page-bg {
      width: 800px;
      height: 100%;
      margin: 0 auto;
      background: white;
    }
  }

  .menu {
    padding-top: 24px;
    width: 240px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .menu-link {
    font-size: 18px;
    font-weight: 400;
    color: #999999;
    text-decoration: none;
    line-height: 26px;
  }

  a.router-link-active, a:hover {
    color: #2C53B0;
    font-weight: 800;
  }

  .seperator {
    margin: 0 9px;
    font-weight: 400;
    color: #999999;
    font-size: 16px;
    line-height: 26px;
  }

  .page-container {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
  }

  .news-list {
    flex-grow: 1;
    list-style-type: none;
    padding: 20px 55px 0px 55px;
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    margin: 0;
  }

  .news-item-text {
    font-size: 14px;
    font-family: PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 24px;
    min-width: 80px;
  }

  .br {
    display: none;
  }

  .news-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #F3F5FB;

    .title {
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      overflow: hidden;
      margin-left: 12px;
      margin-right: 20px;
      font-size: 16px;
      font-family: PingFang-SC;
      font-weight: 400;
      color: #222222;
      line-height: 24px;
    }

    &:hover {
      background-color: #F3F5FB;
      cursor: pointer;

      .title {
        font-family: PingFang-SC-Bold, PingFang-SC;
        font-weight: bold;
        color: #2C53B0;
      }
    }
  }

  .article-footer {
    height: 80px;
    width: 100%;
  }
}

// mobile
@media only screen and (max-width: 600px) {
  .news-list-page {
    background: #f6f6f6;
    height: 100%;

    .policy-bg {
      height: 100%;
      margin: 0 auto;
      background: white;
    }
  }

  .nav-title {
    font-size: 36px;
    font-weight: 600;
    color: #0F64A9;
    font-size: 30px;
    margin: 20px 0px 0px 25px;
  }

  .line {
    margin: 10px 25px 20px 25px;
    background-color: #0F64A9;
    height: 3px;
    width: auto;
  }

  .news-center-page {
    background: #f6f6f6;
    height: 600px;

    .page-bg {
      height: 100%;
      margin: 0 auto;
      background: white;
    }
  }

  .menu {
    padding-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-link {
    font-size: 16px;
    color: #8B8B8B;
    text-decoration: none;
  }

  a.router-link-active, a:hover {
    color: #0F64A9;
    text-decoration: underline;
  }

  .seperator {
    margin: 0 9px;
    font-weight: 700;
    color: #C0C4CC;
    font-size: 14px;
    line-height: 1;
  }

  .page-container {
    margin: 10px 0px;
    display: flex;
    justify-content: center;
    background: white;
  }

  .news-list {
    flex-grow: 1;
    list-style-type: none;
    padding: 10px 25px 0px 25px;
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    margin: 0;
    padding-top: 33px;
  }

  .br {
    margin: 10px 0px;
  }

  .news-item-text {
    font-size: 14px;
    color: #818181;
  }

  .news-item {
    padding: 15px 0px 6px 0px;
    border-bottom: 1px solid #C0C4CC;
  }

  .title:hover {
    color: #0f64a9;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>