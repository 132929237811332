var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "news-list-page" }, [
    _c(
      "div",
      { staticClass: "news-bg" },
      [
        _c("div", { staticClass: "nav-title" }, [_vm._v(_vm._s(_vm.navTitle))]),
        _c("el-divider", {
          staticClass: "line",
          attrs: { "content-position": "left" }
        }),
        _vm.showMenu
          ? _c(
              "div",
              { staticClass: "menu" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "menu-link",
                    attrs: { to: { name: "newsList", params: { type: 3 } } }
                  },
                  [_vm._v("重点要闻")]
                ),
                _c("span", { staticClass: "seperator" }, [_vm._v("/")]),
                _c(
                  "router-link",
                  {
                    staticClass: "menu-link",
                    attrs: { to: { name: "newsList", params: { type: 5 } } }
                  },
                  [_vm._v("地方动态")]
                )
              ],
              1
            )
          : _vm._e(),
        _c(
          "ul",
          { staticClass: "news-list" },
          _vm._l(_vm.newsList, function(item) {
            return _c(
              "li",
              {
                key: item.id,
                staticClass: "news-item news-item-text",
                on: {
                  click: function($event) {
                    return _vm.goToDetail(item)
                  }
                }
              },
              [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.title))
                ]),
                _c("div", { staticClass: "br" }),
                _c("span", { staticClass: "news-item-text" }, [
                  _vm._v(_vm._s(item.publish_time.slice(0, 10)))
                ])
              ]
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "page-container" },
          [
            _c("el-pagination", {
              attrs: {
                "current-page": _vm.page,
                total: _vm.totalPage,
                layout: "prev, pager, next",
                "page-size": 10,
                "hide-on-single-page": true
              },
              on: {
                "update:currentPage": function($event) {
                  _vm.page = $event
                },
                "update:current-page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.pageChange
              }
            })
          ],
          1
        )
      ],
      1
    ),
    _c("div", { staticClass: "article-footer" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }